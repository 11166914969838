export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38')
];

export const server_loads = [];

export const dictionary = {
		"/(app)": [10,[2]],
		"/(app)/admin": [13,[2,4]],
		"/(app)/admin/settings": [14,[2,4]],
		"/(age_verification)/age-verification": [9],
		"/(app)/ai-agent-manager": [15,[2,5]],
		"/(app)/ai-agent-manager/ai-agents": [16,[2,5,6]],
		"/(app)/ai-agent-manager/ai-agents/create": [17,[2,5,6]],
		"/(app)/ai-agent-manager/ai-agents/edit": [18,[2,5,6]],
		"/(app)/ai-agent-manager/documents": [19,[2,5]],
		"/(app)/ai-agent-manager/functions": [20,[2,5]],
		"/(app)/ai-agent-manager/functions/create": [21,[2,5]],
		"/(app)/ai-agent-manager/functions/edit": [22,[2,5]],
		"/(app)/ai-agent-manager/prompts": [23,[2,5]],
		"/(app)/ai-agent-manager/prompts/create": [24,[2,5]],
		"/(app)/ai-agent-manager/prompts/edit": [25,[2,5]],
		"/(app)/ai-agent-manager/tools": [26,[2,5]],
		"/(app)/ai-agent-manager/tools/create": [27,[2,5]],
		"/(app)/ai-agent-manager/tools/edit": [28,[2,5]],
		"/auth": [35],
		"/(app)/(mobile_view)/chats": [11,[2,3]],
		"/(app)/c/[id]": [29,[2]],
		"/(app)/c/[id]/s/[shareId]": [30,[7]],
		"/error": [36],
		"/(app)/manage-team": [31,[2]],
		"/(app)/(mobile_view)/my-profile": [12,[2,3]],
		"/(payment)/payments/[status]": [34],
		"/(app)/playground": [32,[2,8]],
		"/team-invite-link-error": [37],
		"/verification-link-error": [38],
		"/(app)/wallet": [33,[2]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
	
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';